import { render, staticRenderFns } from "./modaldetalhes.vue?vue&type=template&id=03567c4d&scoped=true&"
import script from "./modaldetalhes.vue?vue&type=script&lang=js&"
export * from "./modaldetalhes.vue?vue&type=script&lang=js&"
import style0 from "./modaldetalhes.vue?vue&type=style&index=0&id=03567c4d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03567c4d",
  null
  
)

export default component.exports