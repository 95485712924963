<template>
  <div class="col-md-12">
    <div class="col-md-12 my-5 filter">
      <div class="col-md-12" id="titleCont">
        <div class="col-md-4">
          <p class="titulo_dash mb-2 nav">Data</p>
        </div>
        <div class="col-md-4">
          <div class="col-md-9">
            <p class="titulo_dash mb-2 nav">Unidade</p>
          </div>
          <div class="col-md-3">
            <p class="titulo_dash mb-2 nav">Usuario</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="col-md-5">
            <p class="titulo_dash mb-2 nav">Com Erro</p>
          </div>
          <div class="col-md-7"></div>
        </div>
      </div>
      <div class="col-md-12" id="inputCont">
        <div class="form-group col-md-5" id="dateInputCont">
          <label>Data</label>
          <input
            type="datetime-local"
            class="form-control"
            step="1"
            v-model="filters.dataInit"
          />
          <p class="titulo_dash mx-2 mt-2" id="aTxt">a</p>
          <input
            type="datetime-local"
            class="form-control"
            step="1"
            v-model="filters.dataFinish"
          />
        </div>
        <div class="form-group col-2" id="inputNotDateCont">
          <div class="form-outline" id="subInputCont">
            <label>Usuário</label>
            <select class="maxSelect" @change="ChangeTenant($event)">
              
              <option value v-if="$store.getters.getUser.profile == 1"
                >Master</option
              >
              
              <option
                v-for="(item, index) in this.unidades"
                :key="index"
                :value="item.id"
              >
                {{ LimitName(item.name) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-2" id="inputNotDateCont">
          <div class="form-outline" id="subInputCont">
            <label>Unidade</label>
            <select class="maxSelect" @change="ChangeUser($event)">
              <option value>Todos</option>
              <option
                v-for="(item, index) in this.users"
                :key="index"
                :value="item.userId"
              >
                {{ LimitName(item.userName) }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-1" id="inputNotDateCont">
          <div class="form-outline" id="subInputCont">
            <label>Com Erro</label>
            <select @change="ChangeBoolean($event)">
              <option
                v-for="(item, index) in filters.boolean"
                :key="index"
                :value="item.value"
                :selected="item.value == filters.defaultBoolean"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="col-md-6">
            <button @click="search(0)">Pesquisar</button>
          </div>
          <div class="col-md-6">
            <button @click="InitializeFilters" class="clear">
              Limpar campos
            </button>
          </div>
        </div>
      </div>
    </div>
    <loader color="#e0a638" size="100" v-if="load" />
    <ResultLog
      v-else
      :logs="logs"
      :numberOfLogs="numberOfLogs"
      :msgApresentacao="msg"
      :page="pages"
    />
  </div>
</template>

<script>
import loader from "@/components/utils/_Load";
import HTTP from "@/api_system";
import ResultLog from "./resultlog.vue";

export default {
  components: {
    loader,
    ResultLog
  },
  data() {
    return {
      filters: {},
      users: [],
      unidades: [],
      load: false,
      msgs: [
        "Insira os filtros para iniciar a consulta.",
        "Não foi localizado registro de log para o periodo informado."
      ],
      msg: "",
      logs: [],
      numberOfLogs: 0,
      pages: 0,
      
    };
  },
  created() {
    this.InitializeFilters();
  },
  methods: {
    InitializeFilters() {
      this.CleanFilters();
      this.GetTenants();
    },
    CleanFilters() {
      this.filters = {
        dataInit: "",
        dataFinish: "",
        defaultBoolean: false,
        boolean: [
          {
            name: "Sim",
            value: true
          },
          {
            name: "Não",
            value: false
          }
        ],
        userId: "",
        tenantId: ""
      };
      this.users = [];
      this.unidades = [];
      this.pages = 0;
      this.logs = [];
      this.msg = this.msgs[0];
    },
    search(setPage) {
      if (!this.filters.dataInit || !this.filters.dataFinish) {
        this.$notify({
          group: "erros",
          type: "error",
          text: `<i class="icon ion-close-circled"></i>Informe um periodo para iniciar a consulta`
        });
      } else {
        if (setPage == 0) {
          setPage = 1;
        } else {
          setPage += 1;
        }

        this.pages = setPage;
        this.load = true;
        var requisition = `dateInitial=${this.filters.dataInit}
        &dateFinale=${this.filters.dataFinish}
        &orderStatus=${this.filters.status}
        &paginaAtual=${this.pages}
        &isException=${this.filters.defaultBoolean}`;

        if (this.filters.userId != "") {
          console.log("user");
          requisition += `&userId=${this.filters.userId}`;
        }

        if (this.filters.tenantId != "") {
          requisition += `&tenantId=${this.filters.tenantId}`;
        }

        HTTP.get(`services/app/Auditlogs/GetListAuditLogs?${requisition}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
          .then(res => {
            this.load = false;
            this.logs = res.data.result.auditLogs;
            this.numberOfLogs = res.data.result.numberOfAuditLogs;
            this.pages = this.pages - 1;
          })
          .catch(err => {
            this.load = false;
            this.logs = [];
            this.numberOfLogs = 0;
            this.pages = 0;
            if (err.response.status == 404) {
              this.msg = this.msgs[1];
            } else {
              var msgError =
                err.response.data.error.details != null
                  ? err.response.data.error.details
                  : err.response.data.error.message;

              this.$notify({
                group: "erros",
                type: "error",
                text: `<i class="icon ion-close-circled"></i>${msgError}`
              });
              console.log(err);
            }
          });
      }
    },
    pageChange(pages) {
      console.log(pages);
      // this.getPedidos(pages);
      // this.pages = pages;
    },
    GetTenants() {
      HTTP.post(
        `services/app/Tenant/ListOfTenants`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(res => {
          var unidadesAtivas = res.data.result.activeTenants;
          unidadesAtivas.forEach(unidade => {
            this.unidades.push(unidade);
          });
          if (this.unidades.length == 1) {
            this.filters.tenantId = this.unidades[0].id;
          }
          this.GetUsers();
        })
        .catch(err => console.log(err));
    },
    GetUsers() {
      HTTP.get(
        `services/app/User/GetUsersByTenant?tenantId=${this.filters.tenantId}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      ).then(res => {
        res.data.result.forEach(item => {
          this.users.push(item);
        });
      });
    },
    ChangeTenant(event) {
      this.filters.tenantId = event.target.value;
      this.users = [];
      this.GetUsers();
    },
    ChangeUser(event) {
      this.filters.userId = event.target.value;
    },
    ChangeBoolean(event) {
      this.filters.defaultBoolean = event.target.value;
    },
    LimitName(val) {
      return val.substring(0, 15);
    }
  },
 
};
</script>

<style scoped>
.dropdownCp {
  display: none;
}
.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  color: #175d93;
  cursor: pointer;
}
.fas:hover {
  color: #fff;
}
.vue-ads-cursor-default {
  background-color: transparent;
}
.vue-ads-leading-loose {
  color: white;
}
.nav {
  font-size: 12px;
}
.pedidosVue {
  background: #2474b2;
}
.box {
  min-height: 60px;
  border: 1px solid #e0a638;
  background: #357eb8;
  box-shadow: 0px 3px 6px 0 rgba(68, 68, 68, 0.3);
}

.bolder {
  font-weight: 700;
  font-size: 14px;
}

.filter div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.filter p {
  margin: 0;
}

.filter input {
  height: 20px;
  max-width: 222px;
  background: #357eb8;
  border: 1px solid #e0a638;
  padding: 15px 0 15px 5px;
  color: #fff;
  outline: none;
}

.filter input::placeholder {
  color: #fff;
}

.filter select {
  height: 30px;
  min-width: 100px;
  background-color: #357eb8;
  border: 1px solid #e0a638;
  color: #fff;
  padding: 5px;
  outline: none;
}

.filter .maxSelect {
  min-width: 180px;
}

.filter button {
  width: 100%;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #f38235;
  border: none;
  height: 34px;
}

.filter button.clear {
  background-color: #f65757;
}
.filter button.clear:hover {
  background-color: #e67979;
}

.filter button:hover {
  background-color: #f4ab7b;
}
.centers {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width:768px){
  label{
    display: none;
  }
}
@media screen and (max-width:767px){
  #inputCont{
    flex-direction: column;
  }
  #dateInputCont{
    flex-direction: column;
    align-items: flex-start;
  }
  #titleCont{
    display: none;
  }
  #aTxt{
    margin-bottom: 7px;
  }
  .form-group{
    width: 100%;
  }
  .form-outline{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  #subInputCont{
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  #inputNotDateCont{
    max-width: 100%;
  }
  label{
    color: #FFFF;
    font-size: 14px;
  }
}
</style>
