<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row boxColor align-items-center">
        <div class="col-md-1">
          <p class="erro_titulo mb-3">Erro ocorrido:</p>
        </div>
        <div class="col-md-7">
          <p class="erro_detalhes text-left mb-0">
            {{ errorDetails }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errorDetails: String
  }
};
</script>

<style scoped>
.erro_titulo {
  color: #fff;
  font-size: 13px;
}
.erro_detalhes {
  margin-top: 2px;
  margin-bottom: 2px;
  color: #fff;
  font-weight: 400;
  font-size: 11px;
}
.bolder {
  font-weight: 700;
  font-size: 14px;
}
.boxColor {
  height: auto;
}

.boxColor:nth-child(odd) {
  background-color: #175d93;
}
.boxColor:nth-child(even) {
  background-color: #357eb8;
}
.boxColor:nth-child(odd) .bg_tital {
  background-color: #357eb8;
}
.boxColor:nth-child(even) .bg_tital {
  background-color: #175d93;
}
</style>
