<template>
  <div
    class="modal fade bd-example-modal-lg"
    :id="id"
    tabindex="-1"
    data-backdrop="static"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body containers">
          <div class="card" style="width: 45rem;">
            <div class="card-body">
              <h5 class="card-title">Resultado</h5>
              <p class="card-text">Tempo total: {{ this.detail.time }} ms</p>
              <p class="card-text" v-if="detail.value != null">
                retorno: {{ this.detail.value }} ms
              </p>
            </div>
          </div>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["detail", "id"]
};
</script>

<style scoped>
.close {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  color: #e0a638;
  transition: all 0.1s linear;
  opacity: 0.5;
  position: absolute;
  right: 0px;
  text-shadow: none !important;
  z-index: 6;
  top: 0px;
  padding: 1rem;
  display: block;
  border: none;
  outline: none;
}
.close:hover {
  color: #dc3545;
}
.containers {
  background: #2474b2;
  color: #fff;
}
.card-body {
  background: #175d93;
  border: none;
}
@media screen and (max-width:767px){
  .card{
    width: 100%!important;
  }
  .close{
    margin-right: 10px;
  }
}
</style>
